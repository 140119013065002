<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card>
        <validation-observer ref="formData">
          <b-row>
            <b-col cols="6">
              <b-form-group
                :label="$t('Member')"
                label-for="register-user"
              >
                <v-select
                  id="register-respDataMember"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="respDataMember"
                  :reduce="(respDataMember) => respDataMember"
                  :filterable="false"
                  @search="onSearchMember"
                  @input="selectedMember($event)"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ option.firstName + ' ' + option.lastName }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.firstName + ' ' + option.lastName }}
                  </template>
                  <div
                    slot="list-footer"
                    class="m-1"
                  >
                    <b-row>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="currentPageMember <= 1 ? true :false"
                          @click="nextPageMember(currentPageMember -1)"
                        >
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          block
                          disabled
                        >
                          {{ currentPageMember }}
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="(currentPageMember * perPageMember) >= queriedItemsMember ? true :false"
                          @click="nextPageMember(currentPageMember + 1)"
                        >
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                    </b-row>

                  </div>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Service')"
                label-for="register-service"
              >
                <v-select
                  id="register-service"
                  v-model="serviceId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="respDataServices"
                  :reduce="(respDataServices) => respDataServices"
                  class="select-size-md"
                  @change="selectedService($event)"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Day')"
                label-for="register-day"
              >
                <flat-pickr
                  id="register-day"
                  v-model="day"
                  class="form-control"
                  :config="configs"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Price')"
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Price"
                  vid="price"
                  rules="required"
                >
                  <b-form-input
                    id="price"
                    v-model="price"
                    name="price"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('price')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Start Time')"
                label-for="start-time"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Start Time"
                  vid="start-time"
                  rules="required"
                >
                  <b-form-input
                    id="start-time"
                    v-model="start_time"
                    name="start-time"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Start Time')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small>{{ $t("Example") + " : " + "10:00" }}</small>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('End Time')"
                label-for="end-time"
              >
                <validation-provider
                  #default="{ errors }"
                  name="End Time"
                  vid="end-time"
                  rules="required"
                >
                  <b-form-input
                    id="end-time"
                    v-model="end_time"
                    name="end-time"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('End Time')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small>{{ $t("Example") + " : " + "10:00" }}</small>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Note')"
                label-for="note"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Note"
                  vid="note"
                  rules="required"
                >
                  <b-form-input
                    id="note"
                    v-model="note"
                    name="note"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('note')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Address')"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  vid="address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    name="address"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('address')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Lat')"
                label-for="lat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lat"
                  vid="lat"
                  rules="required"
                >
                  <b-form-input
                    id="lat"
                    v-model="lat"
                    name="lat"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('lat')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Lng')"
                label-for="lng"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lng"
                  vid="lng"
                  rules="required"
                >
                  <b-form-input
                    id="lng"
                    v-model="lng"
                    name="lng"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('lng')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <!-- Action button -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="d-flex mr-2"
            @click="validationForm()"
          >
            {{ $t("Save") }}
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/task'
import flatPickr from 'vue-flatpickr-component'

const STORE_MODULE_NAME = 'task'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    BAvatar,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      customer_name: '',
      customer_tel: '',
      serviceId: '',
      day: '',
      start_time: '',
      end_time: '',
      partnerProfileId: '',
      price: '',
      note: '',
      address: '',
      lat: '',
      lng: '',
      images: [],
      respData: null,
      required,
      configs: {
        minDate: 'today',
        enableTime: false,
        dateFormat: 'l,Y-m-d',
        // locale: Thai,
      },
      searchMember: '',
      currentPageMember: 1,
      perPageMember: 10,
      userInfoId: null,
      userId: null,
    }
  },
  computed: {
    respDataServices() {
      return store.state[STORE_MODULE_NAME].respDataServices != null
        ? store.state[STORE_MODULE_NAME].respDataServices.data
        : []
    },
    respDataMember() {
      return store.state[STORE_MODULE_NAME].respDataMember != null
        ? store.state[STORE_MODULE_NAME].respDataMember.data
        : []
    },
    queriedItemsMember() {
      return store.state[STORE_MODULE_NAME].respDataMember != null ? store.state[STORE_MODULE_NAME].respDataMember.max : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getServices`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    const obj = {
      currentPage: this.currentPageMember,
      pageSize: this.perPageMember,
      searchQuery: this.searchMember,
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getMember`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }
      // this.show = true
      // store
      //   .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      //   // eslint-disable-next-line no-unused-vars
      //   .then(result => {
      //     this.show = false
      //     this.respData = result.data.data
      //     console.log(this.respData)
      //     this.selectedData(this.respData)
      //     // console.log('fetch Success : ', result)
      //     // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      //     // eslint-disable-next-line no-unused-vars
      //   }).catch(error => {
      //     this.show = false
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: 'bottom-right',
      //       props: {
      //         title: this.$t('Error'),
      //         icon: 'ErrorIcon',
      //         variant: 'danger',
      //         text: this.$t(error.response.data.message),
      //       },
      //     })
      //     // console.log('fetchUsers Error : ', error)
      //     // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      //   })
    } else {
      console.log('Else')
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    selectedService(value) {
      console.log(value)
    },
    selectedMember(value) {
      console.log(value)
      this.userInfoId = value._id
      this.userId = value.partnerUserId._id
    },
    nextPageMember(page) {
      this.currentPageMember = page
      this.memberId = ''
      const obj = {
        currentPage: this.currentPageMember,
        pageSize: this.perPageMember,
        searchQuery: this.searchMember,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getMember`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    onSearchMember(query) {
      this.searchMember = query
      this.currentPageMember = 1
      const obj = {
        currentPage: this.currentPageMember,
        pageSize: this.perPageMember,
        searchQuery: this.searchMember,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getMember`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showFromCurrentLanguage(data) {
      console.log(data)
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      if (data.rank.length > 0) {
        for (let index = 0; index < data.rank.length; index += 1) {
          const element = data.rank[index]
          this.ranking.push({
            sequence: element.sequence,
            profilePartnerId: element.profilePartnerId._id,
            name: `${element.profilePartnerId.firstName} ${element.profilePartnerId.lastName}`,
          })
        }
      }
      // this.ranking = data.rank
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
              title: this.$t('Please Confirm'),
              cancelVariant: 'outline-secondary',
              okVariant: 'success',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
              centered: true,
            })
            .then(value => {
              if (value === true) {
                const obj = {
                  partnerUserId: this.userId,
                  serviceId: this.serviceId,
                  day: this.day,
                  start_time: this.start_time,
                  end_time: this.end_time,
                  price: this.price,
                  note: this.note,
                  address: this.address,
                  lat: this.lat,
                  lng: this.lng,
                }
                this.show = true
                store
                  .dispatch(`${STORE_MODULE_NAME}/addTask`, obj)
                  .then(result => {
                    console.log('fetch Success : ', result)
                    this.show = false
                    // this.$router.push({ path: '/hero/queue/template/details' })
                    this.$router.push({ path: '/hero/services/task' })
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: this.$t('Success'),
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: this.$t('Success'),
                      },
                    })
                  })
                  .catch(error => {
                    console.log('fetchUsers Error : ', error)
                    this.show = false

                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                      },
                    })
                  })
              }
            })
        }
      })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // this.$router.push({ path: '/hero/queue/template/details' })
            this.$router.push({ path: '/hero/ranking' })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
